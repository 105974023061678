import RSlider from "../../components/rslider/rslider";
import {onDomReady} from "../../components/dynamic/observer";

function init() {
    const modalContainer = document.querySelector('[data-modal-content="project"]');
    if (!modalContainer) {
        console.log('ModalProjectSlider: Could not found modal container element');
        return;
    }
    const rsliderElement = document.querySelector('[data-rslider="modal-project-slider"]');
    if (!rsliderElement) {
        console.log('ModalProjectSlider: Could not found rslider element');
        return;
    }

    const slider = new RSlider(
        rsliderElement,
        {
            onStart: ({ slideIn }) => {
                slideIn.classList.add('_active');
            },
            swipe: false,
            timeline: [
                RSlider.action({
                    name: 'next-act',
                    duration: 1100,
                    delay: 0,
                    onStart: ({ slideIn, slideOut }) => {
                        slideIn.classList.add('_active');
                        slideOut.classList.remove('_active');

                        slideIn.classList.add('_in-next-act');
                        slideOut.classList.add('_out-next-act');
                    },
                    onEnd: ({ slideIn, slideOut }) => {
                        slideIn.classList.remove('_in-next-act');
                        slideOut.classList.remove('_out-next-act');

                        slideOut.scrollTop = 0;
                    }
                }),
            ],
            timelineReverse: [
                RSlider.action({
                    name: 'next-act',
                    duration: 1100,
                    delay: 0,
                    onStart: ({ slideIn, slideOut }) => {
                        slideIn.classList.add('_active');
                        slideOut.classList.remove('_active');

                        slideIn.classList.add('_in-next-act');
                        slideOut.classList.add('_out-next-act');
                    },
                    onEnd: ({ slideIn, slideOut }) => {
                        slideIn.classList.remove('_in-next-act');
                        slideOut.classList.remove('_out-next-act');

                        slideOut.scrollTop = 0;
                    }
                }),
            ],
        });

    modalContainer.addEventListener('ModalContentOpened', (e) => {
        const slideIndex = e.detail.slideId - 1;
        if (slider.currentIndex !== slideIndex) {
            slider.toSlide(slideIndex);
        }
    });
}


onDomReady(() => init());